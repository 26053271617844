module.exports = {
  global_key: {
    tokenName: 'access_token',
  },
  colors: {
    primaryColor: '#00A380',
    secondaryColor: '#1673B4',
    backgroundColor: 'whitesmoke',
  },
  apiKey: 'gcw8WFKg0He0ch6mxY50h01r8878AUzipjMQL043zSRzP31Q',
};
