<template>
  <div>
    <div :class="$style.divLogin">
      <img src="static/img/resim.png" style="width: 100%; height: 100%; position: absolute; bottom: 0;" alt="">
      <!-- <div :class="$style.divHelpDesk">
        <HelpDesk bgcolor='white' :txtcolor='primaryColor'></HelpDesk>
      </div> -->
      <div :class="$style.loginform">
        <div style="color:white; text-align:center;margin-bottom:70px">
          
            <div :class="$style.logo"> </div>
          
          <!-- <h1>Login</h1> -->
        </div>
        <div v-if="!ready">
          <InnerLoading></InnerLoading>
        </div>
        <div v-else class="row" :class="$style.inputs">
          <!-- <div class="text-center">
                    <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/lotus.webp" style="width: 185px;" alt="logo">
                    <h4 class="mt-1 mb-5 pb-1">We are The Lotus Team</h4>
                  </div> -->
          <div class="col-md-12">
            <i style="position: absolute;margin: 10px; color: #b5badd;" class="fas fa-user"></i>
            <input v-model="username" style="padding-left:30px;border:none;" type="text" class="form-control mb-3" placeholder="User Name" :class="$style.inputText">
            <i style="position: absolute;margin: 10px; color: #b5badd;" class="fas fa-unlock-alt"></i>
            <input v-model="password" style="padding-left:30px;border:none;" type="password" class="form-control mb-3" placeholder="Password" :class="$style.inputText" @keyup.enter="login">
            <button class="btn btn-block" :class="$style.loginButton" @click="login">Login</button>
            <!-- <label class="mt-2 text-center" style="color:#f8f9fac9;user-select: none;">Türkiye içerisindeki öğrencilerimiz sistemi 21 Eylül'den itibaren kullanacaklardır.<br>
              Sağlıklı Günler Dileriz.</label> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapMutations } from 'vuex';
  import _ from 'lodash';
  import swal from 'sweetalert';
  import CryptoJS from 'crypto-js';
  import config from '@/config';
  import InnerLoading from '@/components/InnerLoading';
  import authService from '@/services/auth';
  import axios from 'axios';



  export default {
    name: 'login',
    data() {
      return {
        username: '',
        password: '',
        isChecked: false,
        primaryColor: config.primaryColor,
        ready: true,
        ipAdress: '',
      };
    },
    beforeMount() {
      if(this.username != '' && this.password != ''){
        this.$router.push('/home');
      }
      this.autoLogin();
    },
    mounted() {
      // console.log("M");
      localStorage.clear();
      this.delete_cookie('access_token');
    },
    components: {
      InnerLoading,
    },
    methods: {
      ...mapMutations(['setFirst']),
      async login() {
        this.ready = false;
        const res = await this.$store.dispatch('login', {
          username: this.username,
          password: this.password,
        });
        if (res.success) {
          this.setFirst(true);
          const ipData = await axios.get("https://api.ipify.org?format=json")
          .catch(err => {
              console.log('err', err);
          });

          this.ipAdress = ipData ? ipData.data.ip : '127.0.0.1';

          const userAgent = navigator.userAgent;
          await authService.loginLog(userAgent, this.ipAdress).then(() => {
            this.$router.push('/home');
          }).catch(err => console.log('err', err));
            this.$router.push('/home');
        } else {
          swal('Error!', 'Username or Password is invalid.', 'error');
        }
        this.ready = true;
      },
      async autoLogin() {
        const arr = this.$route.query;
        let jsonArr = {};
        // console.log(_.isEmpty(arr));
        if (_.isEmpty(arr) === false) {
          this.ready = false;
          const decode = decodeURIComponent(atob(arr.sso));
          const secret = 'HJx7t2KxTCHYvtzB4w7AQhVRaf7bTm9WWFQpBMiR@j7Gpgw8';
          const bytes = CryptoJS.AES.decrypt(decode, secret).toString(CryptoJS.enc.Utf8);
          jsonArr = JSON.parse(bytes);
        }
        // console.log(_.isEmpty(jsonArr));
        if (_.isEmpty(jsonArr) === false) {
          if (jsonArr.roleType.toLowerCase() === 'teacher') {
            jsonArr.password = 'Ues1234!';
            if (!jsonArr.classes && jsonArr.class) {
              jsonArr.classes = jsonArr.class;
            }
          } else {
            jsonArr.password = '123456';
            jsonArr.roleType = 'student';
          }
          const res = await this.$store.dispatch('login', {
            username: jsonArr.username,
            password: jsonArr.password,
          });
          // console.log('autoLogin', res);
          if (res.success) {
            this.setFirst(true);
            const ipData = await axios.get("https://api.ipify.org?format=json")
            .catch(err => {
                console.log('err', err);
            });

            this.ipAdress = ipData ? ipData.data.ip : '127.0.0.1';

            const userAgent = navigator.userAgent;
            await authService.loginLog(userAgent, this.ipAdress).then(() => {
              this.$router.push('/home');
            }).catch(err => console.log('err', err));
            this.$router.push('/home');
          } else {
            const res2 = await this.$store.dispatch('loginSso', jsonArr);
            if (res2.success) {
              this.setFirst(true);
              const ipData = await axios.get("https://api.ipify.org?format=json")
              .catch(err => {
                  console.log('err', err);
              });

              this.ipAdress = ipData ? ipData.data.ip : '127.0.0.1';

              const userAgent = navigator.userAgent;
              await authService.loginLog(userAgent, this.ipAdress).then(() => {
                this.$router.push('/home');
              }).catch(err => console.log('err', err));
              this.$router.push('/home');
            } else {
              swal('Error!', 'Giriş yapamazsınız, okul yönetimi ile iletişime geçiniz.', 'error');
            }
          }
        }
        this.ready = true;
      },
      async delete_cookie(name) {
        document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      }
    },
  };

</script>

<style src='./style.less' lang='less' module/>

<style>
  .abcRioButton {
    width: 300px !important;
  }

</style>