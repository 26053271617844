<template>
  <div
    class="row"
    style="background:#fff; border-radius: 10px; box-shadow: 0.5px 0.866px 4px 0px rgba(2, 8, 2, 0.2); padding-bottom: 25px;"
  >
    <div class="col-md-12 col-sm-12 mt-1">
      <div class="row mt-1">
      </div>
      <div class="row mt-2"><!-- Tüm Kullanıcılar -->
      <!-- MYE ASSESSMENTS -->
          <div class="col-md-6 mt-1" v-if="Number(grade) === 5 || Number(grade) === 6 || Number(grade) === 7 || Number(grade) === 8 || Number(grade) === 9 || Number(grade) === 10">
            <!--href="https://app.newsomatic.net/login"--->
              <a :href="`https://www.myeduassessment.com/#/login${querystingtext}`" target="blank">
                <div :class="$style.divPlatform">
                  <div :class="[$style.divPlatformBtn, $style.divPlatformBtnAssessment]"></div>
                  <!-- <div :class="$style.txtPlatform">CLASSROOM</div> -->
                </div>
              </a>
          </div>
        <!-- HLL -->
          <div class="col-md-6 mt-1" v-if="Number(grade) === 2 || Number(grade) === 3 || Number(grade) === 4 || Number(grade) === 5">
            <!--href="https://app.newsomatic.net/login"--->
              <a @click="checkHll()" target="_blank">
                <div :class="$style.divPlatform">
                  <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHLL]"></div>
                  <!-- <div :class="$style.txtPlatform">CLASSROOM</div> -->
                </div>
              </a>
          </div>
        <!-- NOM -->
          <div class="col-md-6 mt-1" v-if="Number(grade) === 6 || Number(grade) === 7">
            <!--href="https://app.newsomatic.net/login"--->
              <a @click="nom()" target="_blank">
                <div :class="$style.divPlatform">
                  <div :class="[$style.divPlatformBtn, $style.divPlatformBtnNewsOMatic]"></div>
                  <!-- <div :class="$style.txtPlatform">CLASSROOM</div> -->
                </div>
              </a>
          </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert2';
import { mapState, mapActions } from "vuex";
import services from "@/services/user";
// import InnerLoading from "@/components/InnerLoading";

export default {
  name: "platforms",
  data() {
    return {
      grade: "",
      level: "",
      roleType: "",
      querystingtext: "",
      kwquerystingtext: "",
      hexaquerystingtext: "",
      zoom: "",
      checkTime: true,
      logCompassSchool: "",
      logCompassUser: "",
      logCompassPass: "",
      ues_digital_code: "",
      ues_digital_code_check: "",
      compass_ready: false,
    };
  },
  computed: {
    ...mapState(["userDetails", "username", "password"]),
    levelName() {
      return this.$store.state.userDetails.organization.find(
        (x) => x.type === "level"
      ).name;
    },
  },
  mounted() {},
  beforeMount() {
    this.setData();
    if (this.userDetails.uesdigital_code != null) {
      this.ues_digital_code = this.userDetails.uesdigital_code;
      this.logCompassUser =
        this.userDetails.compass_username != null
          ? atob(this.userDetails.compass_username)
          : "";
      this.logCompassPass =
        this.userDetails.compass_password != null
          ? atob(this.userDetails.compass_password)
          : "";
      this.logCompassSchool = this.userDetails.compass_school;
    }
  },
  watch: {
    userDetails() {
      this.setData();
    },
    logCompassUser() {},
  },
  components: {
    // InnerLoading,
  },
  methods: {
    ...mapActions(["getUserDetails"]),
    async timeStamp() {
      const res = await services.getTimeStamp();
      if (this.title === "demo") return true;
      if (res.data.is_weekend) return false;
      // eslint-disable-next-line
      return res.data.data.split(" ")[1] > "08:00:00" &&
        res.data.data.split(" ")[1] < "17:00:00"
        ? true
        : false;
    },
    doSomethingOnHidden() {
      //do something
    },
    async setData() {
      if (this.userDetails) {
        this.level = this.userDetails.organization.find(
          (x) => x.type === "level"
        ).name;
        this.grade = this.userDetails.organization.find(
          (x) => x.type === "grade"
        )
          ? this.userDetails.organization
              .find((x) => x.type === "grade")
              .name.split("-")[0]
          : 0;
        this.main = this.userDetails.organization.find(
          (x) => x.type === "main"
        ).id;
        this.zoom = this.userDetails.organization.filter(
          (x) => x.type === "grade"
        );
        this.roleType = this.userDetails.role_type;
        this.title = this.userDetails.title;
        this.querystingtext = `?username=${btoa(this.username)}&password=${btoa(
          this.password
        )}`;
        if (this.userDetails.lang === "en") {
          this.hexaquerystingtext = `?username=${btoa(
            "steve.jobs"
          )}&password=${btoa("090807")}`;
        } else {
          this.hexaquerystingtext = this.querystingtext;
        }
        this.checkTime = await this.timeStamp();
      }
      this.kwquerystingtext = `?username=${btoa(
        "uesnlkw_teacher"
      )}&password=${btoa("123456")}`;
    },
    async checkHll() {
      try {
        this.ready = false;
        // const userDetails = await userService.getStudentDetails();
        const userDetails = await this.userDetails;
        const userId = userDetails.user_id;
        const firstName = userDetails.first_name;
        const lastName = userDetails.last_name;
        // const className = userDetails.organization.find(x => x.type === 'grade').name;
        const userType = userDetails.role_type;
        // const campusId = userDetails.organization.find(y => y.type === 'campus').id;
        const key = userDetails.organization.find(y => y.type === 'campus').hll_company_code;
        // console.log('key', key);
        // const key = obj.find(x => x.schoolId === campusId).companyKey;

        if(userDetails.role_type === "student"){
          const className = encodeURIComponent(userDetails.organization.find(x => x.type === 'grade').name);
          window.go_hll(userId, userId, firstName, lastName, className, userType, key);
        }else if(userDetails.role_type === "teacher"){
          const classes = [];
          await userDetails.organization.filter(x => x.type === 'grade').forEach((item) => {
            classes.push(encodeURIComponent(item.name))
          });
          window.go_hll(userId, userId, firstName, lastName, classes, userType, key);
        }
        setTimeout(() => {
          this.ready = true;
        }, 1000);
      } catch (error) {
        console.log('err', error);
        swal('Giriş Yapılamadı!', 'Bir Hata Oluştu', 'error');
        this.ready = true;
      }
    },
    nom() {

        // this.newsomaticSchool = this.userDetails.compass_school;
        this.newsomaticUser = this.userDetails.compass_username != null ? atob(this.userDetails.compass_username) : '';
        this.newsomaticPass = this.userDetails.compass_password != null ? atob(this.userDetails.compass_password) : '';

      if(this.roleType === 'teacher'){
        window.open('https://app.newsomatic.net/login', '_blank');
      }else{
        if(this.newsomaticUser != ''){
          swal.fire({
            title: 'News-o-Matic',
            html:`        
            <div class="col-md-12 mt-2 text-center" style="display:table-cells" v-else> <!-- Eğer uesDigital kodu girilmişse -->
                        <div class="alert alert-info" role="alert">
                          Aşağıdaki kullanıcı bilgileri ile Go to News-o-Matic linkine tıklayarak hesabınıza giriş yapabilirsiniz.
                        </div>
                        <table class="table table-sm table-striped">
                          <tbody>
                            <tr>
                              <td class="table-primary"><b>User name</b></td>
                              <td class="table-light"><b>${this.newsomaticUser}</b></td>
                            </tr>
                            <tr>
                              <td class="table-primary"><b>Password</b></td>
                              <td class="table-light"><b>${this.newsomaticPass}</b></td>
                            </tr>
                          </tbody>
                        </table>
                    </div>`,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Go to News-o-matic',
          }).then((result) => {
            if (result.value) {
              window.open('https://app.newsomatic.net/login', '_blank');
            }
          });

        }else {
          swal.fire({
            title:'Uyarı!',
            text: 'Kullanıcı bilgileriniz en kısa sürede burada görünecektir...',
            confirmButtonText: 'Kapat',
          });
        }


      }

    },
  },
};
</script>

<style src="./style.less" lang="less" module />

<style scoped>
a {
  text-decoration: none;
  color: black;
}
</style>
